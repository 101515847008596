.banner {
    background-image: url("../../../img/bannerimg.png");
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
    background-size: cover;
}

.sweatimg,
.photoimg {
    width: 300px;
    height: 100%;
}

.name {
    font-size: 72px;
  background: -webkit-linear-gradient(#f79748 , #fff  ,#f37107);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}